<template>
  <!-- 用户管理 机构管理页面 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <router-link :to="{ name: 'managementOrganizationAdd' }">
          <el-button class="addUser-button"> 新增 </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorGetHospitalData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="医院名称"> </el-table-column>
        <el-table-column prop="phone" label="机构电话"> </el-table-column>
        <el-table-column prop="location" label="地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="desc" label="简介" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'managementOrganizationAdd',
                params: { item: scope.row }
              }"
            >
              <el-button size="mini" type="text">编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button @click="showDel([scope.row.id])" size="mini" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <el-button @click="delSelect"> 批量删除 </el-button>

      <pagination
        :current-page="show.startPage"
        :total="show.totalPage"
        @currentChange="editStartPage"
        @sizeChange="sizeChange"
      ></pagination>
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
export default {
  data () {
    return {
      AdministratorGetHospitalDataSelect: [],
      AdministratorGetHospitalData: [],
      show: {
        // 当前页数
        startPage: 1,
        // 每页显示条目个数
        pageSize: 8,
        isGovernment: 0,
        // 总条数
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    // 触发每页的条数
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    delSelect () {
      if (this.AdministratorGetHospitalDataSelect.length <= 0) {
        this.$message({
          type: 'info',
          message: '暂未选中任何机构'
        })
        return
      }
      this.showDel(this.AdministratorGetHospitalDataSelect)
    },
    handleSelectionChange (val) {
      this.AdministratorGetHospitalDataSelect = val.map((item) => {
        return item.id
      })
    },
    del (id) {
      AdministratorService.AdministratorDelHospital({
        delList: id,
        isGovernment: 0
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getData()
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    // 点击删除
    showDel (id) {
      this.$confirm('此操作将永久删除选中的机构, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.del(id)
          // 判断当前页面的数据是否大于0
          if (this.AdministratorGetHospitalDataSelect.length < 1) {
            this.show.startPage = this.show.startPage - 1
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    getData () {
      AdministratorService.AdministratorGetHospital(this.show).then((res) => {
        this.AdministratorGetHospitalData = res.data.hospitalList
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container {
      display: flex;
      align-items: center;
      .seek-section {
        border: 1px solid #34bbec;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner {
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search {
          color: #999999;
        }
      }
      .seek-button {
        width: 80px;
        background-color: #34bbec;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container {
      .addUser-button {
        background-color: #34bbec;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container {
    flex: 1;
  }
  .footer-container {
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button {
      margin-right: 160px;
    }
  }
}
</style>
